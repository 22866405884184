import {
  Badge,
  Box,
  ButtonGroup,
  Divider,
  Flex,
  HStack,
  IconButton,
  Text,
  Tooltip,
  useColorModeValue,
  useToast,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
  Radio,
  RadioGroup,
  Input,
  Spinner,
  Stack,
  Textarea,
  CheckboxGroup,
  Checkbox,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";
import Card from "components/Card/Card.js";
import Markdown from "react-markdown";
import CardBody from "components/Card/CardBody.js";
import { format } from "date-fns";
import { getUserName } from "extensions/userInfoExtension";
import { getUserId } from "extensions/userInfoExtension";
import React, { useRef, useEffect, useState } from "react";
import {
  FaFileContract,
  FaShare,
  FaFileWord,
  FaEdit,
  FaFilePdf,
  FaTrashAlt,
  FaArrowLeft,
  FaCheck,
} from "react-icons/fa";
import { FaBook } from "react-icons/fa6";
import { saveAs } from "file-saver";
import { Packer } from "docx";
import ChakraUIRenderer from "chakra-ui-markdown-renderer";
import { LawDiskDocumentCreator } from "../../../../services/LawDiskDocumentCreator";
import { LawDiskPDFCreator } from "../../../../services/LawDiskPDFCreator";
import { useHistory } from "react-router-dom"; // Voor navigatie
import lawdiskLogo from "../../../../assets/img/lawdisk.png";
import { deleteConversation } from "api/lawdiskService";
import { updateConversation } from "api/lawdiskService";
import { addMetadataConversation } from "api/lawdiskService";
import { IoRadioButtonOff } from "react-icons/io5";
import { GrCheckboxSelected } from "react-icons/gr";

const NotulenDetailCard = ({
  id,
  summary,
  text,
  betterText,
  date,
  title,
  tags,
  userId,
  onDelete,
}) => {
  const toast = useToast();
  const history = useHistory(); // Voor navigatie
  const textColor = useColorModeValue("gray.700", "white");
  const summaryRef = useRef(null);
  const [summaryHeight, setSummaryHeight] = useState(0);

  // Summary settings
  const [summarySettings, setSummarySettings] = useState(false);
  const [summaryType, setSummaryType] = useState("middel"); // Detailniveau
  const [summaryTone, setSummaryTone] = useState("zakelijk"); // Toon
  const [summaryFormat, setSummaryFormat] = useState([]); // Vorm (bulletpoints, etc.)
  const [additionalRequests, setAdditionalRequests] = useState("");
  const [isGeneratingSummary, setIsGeneratingSummary] = useState(false);

  useEffect(() => {
    if (summaryRef.current) {
      setSummaryHeight(summaryRef.current.offsetHeight + 200);
    }
  }, [summary]);

  const textRef = useRef(null);
  // (Let op: de state 'scrollable' wordt hier ingesteld maar verder niet gebruikt.)
  useEffect(() => {
    if (textRef.current && summaryRef.current) {
      const textHeight = textRef.current.scrollHeight;
      const summaryHeight = summaryRef.current.offsetHeight;
      if (textHeight > summaryHeight) {
        setScrollable(true);
      } else {
        setScrollable(false);
      }
    }
  }, [text, summary]);

  const userFriendlyDate =
    date && !isNaN(new Date(date).getTime())
      ? format(new Date(date), "dd-MM-yyyy HH:mm:ss")
      : "onbekend";

  const formattedDate =
    date && !isNaN(new Date(date).getTime())
      ? format(new Date(date), "dd-MM")
      : "onbekend";

  const userName = getUserId() === userId ? getUserName() : userId;

  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [documentOption, setDocumentOption] = useState("0");
  const [conversationIdToDelete, setConversationIdToDelete] = useState(null);
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [editableTitle, setEditableTitle] = useState(title);
  // Als er al een samenvatting bestaat, wordt het menu standaard verborgen.
  const [showSummaryOptions, setShowSummaryOptions] = useState(
    !summary || summary.trim() === ""
  );

  const cancelDialogRef = useRef();
  const cancelDeleteRef = useRef();

  const openWordDialog = () => setIsDialogOpen(true);
  const closeDialog = () => setIsDialogOpen(false);
  const openDeleteDialog = () => setIsDeleteDialogOpen(true);
  const closeDeleteDialog = () => setIsDeleteDialogOpen(false);

  const generateWordDocument = () => {
    const isSummaryOnly = documentOption === "0";
    const documentCreator = new LawDiskDocumentCreator();
    const doc = documentCreator.create({
      summary: summary,
      transcript: isSummaryOnly ? "" : text,
      title: title,
      date: userFriendlyDate,
      tags: tags,
      isSummaryOnly: isSummaryOnly,
    });
    Packer.toBlob(doc).then((blob) => {
      const documentName = `LawDisk_Notulen_${formattedDate}.docx`;
      saveAs(blob, documentName);
      toast({
        title: "Word-document gegenereerd",
        description: "Het notulen Word-document is succesvol gegenereerd.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    });
  };

  const generatePDF = () => {
    const isSummaryOnly = documentOption === "0";
    const pdfCreator = new LawDiskPDFCreator();
    pdfCreator.create(
      {
        summary: summary,
        transcript: text,
        date: userFriendlyDate,
        tags: tags,
        isSummaryOnly: isSummaryOnly,
      },
      lawdiskLogo,
      userId,
      (pdfDoc) => {
        pdfCreator.savePDF(pdfDoc, formattedDate);
        toast({
          title: "PDF gegenereerd",
          description: "Het notulen PDF-bestand is succesvol gegenereerd.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
    );
  };

  const UpdateTitle = async () => {
    try {
      await updateConversation(id, null, editableTitle);
      toast({
        title: "Titel aangepast",
        description: "De titel is succesvol bijgewerkt.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (e) {
      console.error(e);
      toast({
        title: "Fout",
        description: "Er is iets misgegaan bij het aanpassen van de titel.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const [currentText, setCurrentText] = useState(text);
  useEffect(() => {
    if (text) {
      setCurrentText(text);
    }
  }, [text]);

  const handleImprovedTranscript = () => {
    if (!betterText || betterText.trim() === "") {
      toast({
        title: "Verbeterde transcriptie niet beschikbaar",
        description:
          "De verbeterde transcriptie is niet beschikbaar voor deze notulen. Notulen gemaakt voor de update 0.4.0 hebben geen verbeterde transcriptie.",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
      return;
    }
    setCurrentText(betterText);
    toast({
      title: "Verbeterde transcriptie geladen",
      description: "De transcriptie is succesvol verbeterd.",
      status: "success",
      duration: 5000,
      isClosable: true,
    });
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth < 600);
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 600);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    // Als er een niet-lege samenvatting is, verberg dan het opties-menu
    if (summary && summary.trim() !== "") {
      setShowSummaryOptions(false);
    }
  }, [summary]);

  const handleOriginalTranscript = () => {
    setCurrentText(text);
    toast({
      title: "Originele transcriptie hersteld",
      description: "De transcriptie is teruggezet naar de originele versie.",
      status: "info",
      duration: 5000,
      isClosable: true,
    });
  };

  const ConversationDelete = async (notulenId) => {
    try {
      const idToDelete = notulenId.id || notulenId;
      console.log("Deleting conversation with ID:", idToDelete);
      if (idToDelete !== null) {
        await deleteConversation(idToDelete);
      }
      if (onDelete) {
        onDelete();
      }
      setConversationIdToDelete(null);
      closeDeleteDialog();
      toast({
        title: "Verwijderd",
        description: "De notulen zijn succesvol verwijderd.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      history.push("/admin/dashboard/notulen");
    } catch (error) {
      console.error("Error deleting conversation:", error);
      toast({
        title: "Fout",
        description: "Er is iets misgegaan bij het verwijderen.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Back button styling
  const tooltipBg = useColorModeValue("gray.300", "gray.700");
  const buttonBg = useColorModeValue("gray.200", "gray.600");
  const buttonHoverBg = useColorModeValue("gray.300", "gray.500");
  const iconColor = useColorModeValue("gray.800", "white");

  return (
    <>
      <Flex position="relative" mb="20px">
        <Flex position="absolute" top="0" left="-10px" zIndex="10">
          <Tooltip
            hasArrow
            label="Ga terug naar Dashboard"
            bg={tooltipBg}
            color={useColorModeValue("black", "white")}
          >
            <IconButton
              icon={<FaArrowLeft />}
              aria-label="Terug naar Dashboard"
              color={iconColor}
              onClick={() => history.push("/admin/dashboard")}
              borderRadius="50%"
              boxSize="50px"
              bg={buttonBg}
              _hover={{ bg: buttonHoverBg }}
            />
          </Tooltip>
        </Flex>
      </Flex>

      <Card minHeight="290.5px" p="1.2rem">
        <CardBody w="100%">
          <Box></Box>
          <Flex flexDirection={{ sm: "column", lg: "row" }} w="100%">
            <Flex
              flexDirection="column"
              h="100%"
              lineHeight="1.6"
              width={{ lg: "45%" }}
              p={4}
            >
              <Flex align="center" mt={2}>
                {!isEditingTitle ? (
                  <Flex align="center">
                    <Text as="span" color="gray.500" fontWeight="bold" mr={2}>
                      {editableTitle && editableTitle !== ""
                        ? editableTitle
                        : title && title !== ""
                        ? title
                        : "Notulen"}
                    </Text>
                    <IconButton
                      aria-label="Titel bewerken"
                      icon={<FaEdit />}
                      size="m"
                      fontSize="25px"
                      variant="ghost"
                      onClick={() => {
                        setIsEditingTitle(true);
                        setEditableTitle(title);
                      }}
                      color="blue.500"
                    />
                  </Flex>
                ) : (
                  <Flex align="center" gap={2}>
                    <Input
                      placeholder="Titel invoeren..."
                      variant="flushed"
                      value={editableTitle}
                      onChange={(e) => setEditableTitle(e.target.value)}
                      autoFocus
                      fontSize="m"
                      fontWeight="bold"
                      color="gray.500"
                      _focus={{ borderColor: "blue.500" }}
                      height="1.5rem"
                      width={{ base: "100%", md: "360px" }}
                    />
                    <IconButton
                      aria-label="Opslaan"
                      icon={<FaCheck />}
                      size="sm"
                      fontSize="20px"
                      colorScheme="blue"
                      onClick={() => {
                        setIsEditingTitle(false);
                        UpdateTitle();
                      }}
                    />
                  </Flex>
                )}
              </Flex>

              <Text color="gray.400" fontSize="sm" fontWeight="normal">
                Notulen gestart door:{" "}
                <Text as="span" color="gray.500" fontWeight="bold">
                  {userName}
                </Text>
              </Text>
              <Text color="gray.400" fontSize="sm" fontWeight="normal">
                Datum notulen:{" "}
                <Text as="span" color="gray.500" fontWeight="bold">
                  {userFriendlyDate}
                </Text>
              </Text>
              <Text color="gray.400" fontSize="sm" fontWeight="normal">
                ID:{" "}
                <Text as="span" color="gray.500" fontWeight="bold">
                  {id}
                </Text>
              </Text>
              <Flex>
                <HStack
                  p={4}
                  flexDirection={{ sm: "column", md: "row" }}
                  h="100%"
                  lineHeight="1.6"
                  width={{ lg: "100%" }}
                  flexWrap="wrap"
                >
                  {tags?.map((tag, index) => (
                    <Box key={index}>
                      <Badge colorScheme="green" textAlign="center">
                        {tag}
                      </Badge>
                    </Box>
                  ))}
                </HStack>
              </Flex>

              <Flex flexDirection="column" justifyContent="flex-start" mb="1rem">
                <ButtonGroup gap="2" align="center">
                  <Tooltip hasArrow label="Delen" bg="gray.300">
                    <IconButton
                      colorScheme="purple"
                      aria-label="Delen"
                      fontSize="20px"
                      icon={<FaShare />}
                      onClick={() =>
                        toast({
                          title: "Binnenkort beschikbaar",
                          description:
                            "Het delen van de notulen is binnenkort beschikbaar.",
                          duration: 9000,
                          isClosable: true,
                        })
                      }
                    />
                  </Tooltip>
                  <Tooltip
                    hasArrow
                    label="Genereer PDF / Word document"
                    bg="gray.300"
                  >
                    <IconButton
                      colorScheme="blue"
                      aria-label="Genereer PDF / Word document"
                      fontSize="20px"
                      icon={<FaFileWord />}
                      onClick={openWordDialog}
                    />
                  </Tooltip>
                  <Tooltip hasArrow label="Notulen verwijderen" bg="gray.300">
                    <IconButton
                      colorScheme="red"
                      aria-label="Notulen verwijderen"
                      fontSize="20px"
                      icon={<FaTrashAlt />}
                      onClick={openDeleteDialog}
                    />
                  </Tooltip>
                </ButtonGroup>
              </Flex>

              <Box mt={4}>
                <Divider />
                <Text fontSize="sm" fontWeight="bold" textAlign="center" mt={2}>
                  SAMENVATTING
                </Text>
                <Divider />
              </Box>

              <Flex flexDirection="column" mt={4}>
                {/* Samenvatting display area */}
                {summary && summary.trim() !== "" ? (
                  <Box>
                    <Text
                      ref={summaryRef}
                      fontSize="sm"
                      color={textColor}
                      fontWeight="normal"
                    >
                      <Markdown
                        components={ChakraUIRenderer()}
                        children={summary}
                        skipHtml
                      />
                    </Text>
                    <Button
                      variant="link"
                      size="sm"
                      mt={2}
                      onClick={() => setShowSummaryOptions((prev) => !prev)}
                    >
                      {showSummaryOptions
                        ? "Verberg samenvatting opties"
                        : "Toon samenvatting opties"}
                    </Button>
                  </Box>
                ) : (
                  <Flex align="center" justify="center" flexDirection="column">
                    <Text fontSize="sm" color={textColor} fontWeight="bold">
                      Genereer zelf uw samenvatting aan de hand van de gewenste instellingen..
                    </Text>
                    <Button
                      variant="link"
                      size="sm"
                      mt={2}
                      onClick={() => setShowSummaryOptions((prev) => !prev)}
                    >
                      {showSummaryOptions ? "Verberg opties" : "Toon opties"}
                    </Button>
                  </Flex>
                )}

                {/* Collapsible summary generation options met Apple-style laadoverlay */}
                {showSummaryOptions && (
                  <Box position="relative">
                    <Flex
                      align="center"
                      justify="center"
                      mt={4}
                      flexDirection="column"
                      border="1px"
                      borderColor={useColorModeValue("gray.200", "gray.600")}
                      p={4}
                      borderRadius="md"
                      bg={useColorModeValue("white", "gray.700")}
                    >
                      <Text mb={2} fontWeight="bold">
                        Kies detailniveau
                      </Text>
                      <RadioGroup onChange={setSummaryType} value={summaryType}>
                        <Stack direction="column">
                          <Radio value="laag">Korte samenvatting</Radio>
                          <Radio value="middel">Gemiddeld detail</Radio>
                          <Radio value="hoog">Zeer uitgebreid</Radio>
                        </Stack>
                      </RadioGroup>

                      <Text mt={4} fontWeight="bold">
                        Kies extra opties
                      </Text>
                      <CheckboxGroup
                        value={summaryFormat}
                        onChange={(values) => setSummaryFormat(values)}
                      >
                        <Stack direction="column">
                          <Checkbox value="bulletpoints">
                            Gebruik bulletpoints
                          </Checkbox>
                          <Checkbox value="actiepunten">
                            Markeer actiepunten
                          </Checkbox>
                          <Checkbox value="besluiten">
                            Voeg besluitenlijst toe
                          </Checkbox>
                        </Stack>
                      </CheckboxGroup>

                      <FormControl mt={4}>
                        <FormLabel>Overige wensen:</FormLabel>
                        <Textarea
                          placeholder="Vul hier eventuele extra instructies in..."
                          value={additionalRequests}
                          onChange={(e) =>
                            setAdditionalRequests(e.target.value)
                          }
                        />
                      </FormControl>

                      <Button
                        mt={4}
                        onClick={async () => {
                          setIsGeneratingSummary(true);
                          // Bouw de payload voor de metadata
                          const payload = {
                            summaryType,
                            summaryFormat: {
                              bulletpoints: summaryFormat.includes("bulletpoints"),
                              actiepunten: summaryFormat.includes("actiepunten"),
                              besluiten: summaryFormat.includes("besluiten"),
                            },
                            additionalRequests:
                              additionalRequests.trim() === ""
                                ? null
                                : additionalRequests,
                          };

                          try {
                            await addMetadataConversation(id, payload);
                            // toast({
                            //   title: "Samenvatting gegenereerd",
                            //   description:
                            //     "Opties voor de samenvatting zijn succesvol toegevoegd.",
                            //   status: "success",
                            //   duration: 5000,
                            //   isClosable: true,
                            // });
                            // Verberg het menu en reload de pagina na een korte pauze
                            setShowSummaryOptions(false);
                            setTimeout(() => {
                              window.location.reload();
                            }, 2000);
                          } catch (error) {
                            console.error(
                              "Error generating summary metadata:",
                              error
                            );
                            toast({
                              title: "Fout",
                              description:
                                "Er is iets misgegaan bij het genereren van de samenvatting.",
                              status: "error",
                              duration: 5000,
                              isClosable: true,
                            });
                          } finally {
                            setIsGeneratingSummary(false);
                          }
                        }}
                        isLoading={isGeneratingSummary}
                        loadingText="Laden..."
                      >
                        {summary && summary.trim() !== ""
                          ? "Regenereer samenvatting"
                          : "Genereer samenvatting"}
                      </Button>
                    </Flex>
                    {isGeneratingSummary && (
                      <Box
                        position="absolute"
                        top={0}
                        left={0}
                        width="100%"
                        height="100%"
                        bg="rgba(245,245,245,0.8)"
                        borderRadius="md"
                        zIndex={10}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Flex direction="column" align="center">
                        <Spinner size="xl" color="blue.500" />
<Text mt={4} fontSize="lg" color="blue.800" fontWeight="bold">
  Even geduld, laden...
</Text>
                        </Flex>
                      </Box>
                    )}
                  </Box>
                )}
              </Flex>
            </Flex>

            <Flex flexDirection="column" width={{ lg: "55%" }} p={4}>
              <Text
                fontSize={{ sm: "md", md: "lg", lg: "md" }}
                align="center"
                color={textColor}
                fontWeight="bold"
              >
                Volledige transcriptie
              </Text>
              <Divider />
              <Divider />
              <Button
                colorScheme="purple"
                onClick={
                  currentText === betterText
                    ? handleOriginalTranscript
                    : handleImprovedTranscript
                }
              >
                {currentText === betterText
                  ? isMobile
                    ? "Terug naar origineel"
                    : "Terug naar het oorspronkelijke transcript."
                  : isMobile
                  ? "Verbeter transcriptie"
                  : "Klik hier voor: verbeterde transcriptie inclusief interpunctie, alinea's en contextanalyse."}
              </Button>
              <Flex
                justify="center"
                borderRadius="15px"
                width="100%"
                mt={4}
                height="auto"
                overflowY="auto"
                border="1px solid"
                borderColor={useColorModeValue("gray.200", "gray.600")}
                p="10px"
                bg={useColorModeValue("gray.100", "gray.800")}
              >
                <Box position="relative" padding="10">
       <Markdown
                  components={ChakraUIRenderer()}
                  children={currentText}
                  skipHtml
                    />

                </Box>
              </Flex>
            </Flex>
          </Flex>
        </CardBody>

        <AlertDialog
          isOpen={isDialogOpen}
          leastDestructiveRef={cancelDialogRef}
          onClose={closeDialog}
          isCentered
        >
          <AlertDialogOverlay>
            <AlertDialogContent borderRadius="15px" position="relative">
              <IconButton
                icon={<Text fontSize="xl">✕</Text>}
                aria-label="Sluit dialoogvenster"
                position="absolute"
                top="8px"
                right="8px"
                colorScheme="gray"
                variant="ghost"
                onClick={closeDialog}
                _hover={{ bg: "gray.100" }}
              />
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Kies documentformaat en inhoud
              </AlertDialogHeader>
              <Divider />
              <AlertDialogBody>
                <Text mt={4}>Kies het formaat voor het document:</Text>
                <Flex justify="space-around" mt="4" mb="1rem">
                  <IconButton
                    colorScheme="blue"
                    aria-label="Genereer Word Document"
                    icon={<FaFileWord />}
                    fontSize="30px"
                    width="60px"
                    height="60px"
                    boxShadow="md"
                    _hover={{ transform: "scale(1.1)", boxShadow: "lg" }}
                    onClick={() => {
                      generateWordDocument();
                      closeDialog();
                    }}
                  />
                  <IconButton
                    colorScheme="red"
                    aria-label="Genereer PDF"
                    icon={<FaFilePdf />}
                    fontSize="30px"
                    width="60px"
                    height="60px"
                    boxShadow="md"
                    _hover={{ transform: "scale(1.1)", boxShadow: "lg" }}
                    onClick={() => {
                      generatePDF();
                      closeDialog();
                    }}
                  />
                </Flex>
                <RadioGroup
                  onChange={setDocumentOption}
                  value={documentOption}
                  mt={4}
                >
                  <Flex flexDirection="column">
                    <Radio value="0">Alleen de samenvatting.</Radio>
                    <Radio value="1">Samenvatting en transcriptie.</Radio>
                  </Flex>
                </RadioGroup>
              </AlertDialogBody>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>

        <AlertDialog
          isOpen={isDeleteDialogOpen}
          leastDestructiveRef={cancelDeleteRef}
          onClose={closeDeleteDialog}
          isCentered
        >
          <AlertDialogOverlay>
            <AlertDialogContent borderRadius="15px" position="relative">
              <AlertDialogHeader
                position="relative"
                fontSize="lg"
                fontWeight="bold"
                isCentered
              >
                Verwijderen notulen
              </AlertDialogHeader>
              <AlertDialogBody>
                Weet u zeker dat u deze notulen wilt verwijderen? Deze actie kan
                niet ongedaan gemaakt worden.
              </AlertDialogBody>
              <AlertDialogFooter>
                <Button ref={cancelDeleteRef} onClick={closeDeleteDialog}>
                  Annuleren
                </Button>
                <Button
                  colorScheme="red"
                  onClick={() => ConversationDelete(id)}
                  ml={3}
                >
                  Verwijderen
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </Card>
    </>
  );
};

export default NotulenDetailCard;
