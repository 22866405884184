import {
  FormLabel,
  form,
  Input,
  InputRightElement,
  InputGroup,
  IconButton,
  List,
  ListItem,
  ListIcon,
  Collapse,
  Button,
} from "@chakra-ui/react";

import { CheckCircleIcon, WarningIcon } from "@chakra-ui/icons";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import React, { useState, useEffect } from "react";

function PasswordField({ value, onChange, placeholder }) {
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  return (
    <InputGroup>
      <Input
        borderRadius="15px"
        mb="10px"
        w="100%"
        fontSize="sm"
        type={show ? "text" : "password"}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        size="lg"
      />
      <InputRightElement h="full">
        <IconButton
          aria-label={show ? "Verberg wachtwoord" : "Toon wachtwoord"}
          icon={show ? <FaEyeSlash /> : <FaEye />}
          size="sm"
          mb="10px"
          variant="ghost"
          onClick={handleClick}
        />
      </InputRightElement>
    </InputGroup>
  );
}

function PasswordInput({ password, onPasswordChange, showValidation }) {
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [isListVisible, setIsListVisible] = useState(false);

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    onPasswordChange(newPassword);
    setIsListVisible(newPassword.trim() !== "");
  };

  const handlePasswordConfirmChange = (e) => {
    const newPasswordConfirm = e.target.value;
    setPasswordConfirm(newPasswordConfirm);
  };
  // Wachtwoord laten zien in de UI
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);

  const [show1, setShow1] = React.useState(false);
  const handleClick1 = () => setShow1(!show1);

  const criteria = {
    hasUpperCase: /[A-Z]/.test(password),
    hasLowerCase: /[a-z]/.test(password),
    hasEightChars: password.length >= 8,
    hasSpecialChar: /[!@#$%^&*(),.?":{}|<>]/g.test(password),
    hasNumber: /\d/.test(password),
    hasNoTrailingSpaces: /\s/g.test(password) === false,
  };

  const [match, setMatch] = useState(false);

  const isPasswordValid = showValidation
    ? Object.values(criteria).every(
        (value) => value === true && password === passwordConfirm
      )
    : false;

  useEffect(() => {
    if (showValidation === true) {
      setMatch(password === passwordConfirm);
    }
  }, [password, passwordConfirm, showValidation]);

  useEffect(() => {
    if (onPasswordChange) {
      onPasswordChange(password, isPasswordValid);
    }
  }, [password, isPasswordValid, onPasswordChange]);

  return (
    <>
      <PasswordField
        value={password}
        onChange={handlePasswordChange}
        placeholder="Uw wachtwoord"
      />
      {showValidation && (
        <>
          <FormLabel ms="4px" fontSize="xs" fontWeight="normal">
            <Collapse in={isListVisible} animateOpacity>
              <List spacing={3}>
                <ListItem>
                  <ListIcon
                    as={criteria.hasEightChars ? CheckCircleIcon : WarningIcon}
                    color={criteria.hasEightChars ? "green.500" : "red.500"}
                  />
                  Wachtwoord moet minstens 8 tekens bevatten
                </ListItem>
                <ListItem>
                  <ListIcon
                    as={criteria.hasLowerCase ? CheckCircleIcon : WarningIcon}
                    color={criteria.hasLowerCase ? "green.500" : "red.500"}
                  />
                  Wachtwoord moet een kleine letter bevatten
                </ListItem>
                <ListItem>
                  <ListIcon
                    as={criteria.hasUpperCase ? CheckCircleIcon : WarningIcon}
                    color={criteria.hasUpperCase ? "green.500" : "red.500"}
                  />
                  Wachtwoord moet een hoofdletter bevatten
                </ListItem>
                <ListItem>
                  <ListIcon
                    as={criteria.hasSpecialChar ? CheckCircleIcon : WarningIcon}
                    color={criteria.hasSpecialChar ? "green.500" : "red.500"}
                  />
                  Wachtwoord moet een speciaal teken bevatten
                </ListItem>
                <ListItem>
                  <ListIcon
                    as={criteria.hasNumber ? CheckCircleIcon : WarningIcon}
                    color={criteria.hasNumber ? "green.500" : "red.500"}
                  />
                  Wachtwoord moet een nummer bevatten
                </ListItem>
                <ListItem>
                  <ListIcon
                    as={
                      criteria.hasNoTrailingSpaces
                        ? CheckCircleIcon
                        : WarningIcon
                    }
                    color={
                      criteria.hasNoTrailingSpaces ? "green.500" : "red.500"
                    }
                  />
                  Wachtwoord mag geen voor- of achterspaties bevatten
                </ListItem>
              </List>
            </Collapse>
          </FormLabel>
          <PasswordField
            value={passwordConfirm}
            onChange={handlePasswordConfirmChange}
            placeholder="Herhaal uw wachtwoord"
          />
          <FormLabel ms="4px" fontSize="xs" fontWeight="normal">
            <Collapse in={isListVisible} animateOpacity>
              <List spacing={3}>
                <ListItem>
                  <ListIcon
                    as={match ? CheckCircleIcon : WarningIcon}
                    color={match ? "green.500" : "red.500"}
                  />
                  Wachtwoorden komen overeen
                </ListItem>
              </List>
            </Collapse>
          </FormLabel>
        </>
      )}
    </>
  );
}

export default PasswordInput;
