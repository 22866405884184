// RecordingButton.js
import React, { useContext, useState, useEffect, useRef } from "react";
import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  HStack,
  IconButton,
  Popover,
  PopoverTrigger,
  Portal,
  PopoverContent,
  PopoverArrow,
  PopoverHeader,
  PopoverCloseButton,
  PopoverBody,
  Select,
  Text,
  useColorModeValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button as ChakraButton,
} from "@chakra-ui/react";
import { FaMicrophone, FaLock, FaArrowRight } from "react-icons/fa";
import { SettingsIcon } from "@chakra-ui/icons";
import WaveBox from "../../components/Recording/WaveBox";
import CircularWaveform from "../../components/Recording/CircularWaveBox"; // Adjusted import
import RecordingContext from "../../contexts/RecordingContext"; // Adjust the path accordingly
import SettingsContext from "contexts/SettingsContext"; // Adjust the path accordingly
import { useMediaQuery } from "react-responsive";
import { PulseLoader } from "react-spinners";
import useNavigatorOnline from "use-navigator-online";

const mySound = require("../../assets/sounds/start.mp3");
const mySound2 = require("../../assets/sounds/done.wav");
const mySound3 = require("../../assets/sounds/error.mp3");




function RecordingButton({ onStopRecording }) {
  const {
    isRecording,
    startRecording,
    buffer,
    model,
    setModel,
    conversation,
    stopRecording,
    isDisabled,
    waitingForSetup,
    mediaStream,
  } = useContext(RecordingContext);

  const { liveTranscriptionEnabled, waveformVisible, isJuvV2 } = useContext(
    SettingsContext
  );

  

  const [devices, setDevices] = useState([]);
  const [selectedDeviceId, setSelectedDeviceId] = useState(null);
  const isMobile = useMediaQuery({ query: "(max-width: 800px)" });
  const { isOnline } = useNavigatorOnline();
  const [offlineSince, setOfflineSince] = useState(null);
  const [isPaused, setIsPaused] = useState(false);

  // Pause button for off-the record statements. Temporarerly pause recording without losing websocket connection.
  const handleTogglePause = () => {
    if (!mediaStream) return; 
    if (!isPaused) {
      // Pause 
      mediaStream.getAudioTracks().forEach((track) => (track.enabled = false));
      setIsPaused(true);
    } else {
      // Resume
      mediaStream.getAudioTracks().forEach((track) => (track.enabled = true));
      setIsPaused(false);
    }
  };

  // For auto-scrolling conversation preview
  const scrollRef = useRef(null);

  // Fetch available audio input devices
  useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then((deviceInfos) => {
      const audioDevices = deviceInfos.filter(
        (device) => device.kind === "audioinput"
      );
      setDevices(audioDevices);
      if (audioDevices.length > 0) {
        setSelectedDeviceId(audioDevices[0].deviceId);
      }
    });
  }, []);

  const offlineSound = new Audio(mySound3.default || mySound3);
  offlineSound.load();

    // ALog going offline

    useEffect(() => {
      if (!isOnline && offlineSince === null) {
        setOfflineSince(new Date());
        offlineSound.play().catch((error) => {
        window.focus();

        });
      }
    
      if (isOnline && offlineSince !== null) {
        setOfflineSince(null);
        window.location.reload();
      }
    }, [isOnline, offlineSince]);

  const handleClick = async () => {
    const startSound = new Audio(mySound.default || mySound);
    const stopSound = new Audio(mySound2.default || mySound2); // Geluid bij stoppen
    

  
    if (isRecording) {
      await stopRecording();
      if (onStopRecording) onStopRecording();
      stopSound.play().catch((error) => {
      });
      

    } else {
      await startRecording(selectedDeviceId, isJuvV2);
      setIsPaused(false);
     
      startSound.play().catch((error) => {
      });
    }
  };
  

  const handleDeviceChange = (event) => {
    setSelectedDeviceId(event.target.value);
  };

  // Auto-scroll to bottom when conversation or buffer changes
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, [conversation, buffer]);

  const audio = document.getElementById("audio_tag");
  const [play, setPlay] = useState(false);
  
  // Define available models
  const availableV2Models = [
    { value: "generic", label: "Generiek" },
    { value: "kolibri", label: "Ooievaar [Experimenteel]" },
    { value: "multilingual", label: "Meertalig [NL vertaling]" },
    // Add more models as needed
  ];

  return (
    <>
       {/* 
        Modal voor "offline" error. Deze isOpen als we offline zijn.
        Zo kan de gebruiker niet doorwerken tot er weer verbinding is.
      */}
      <Modal isOpen={!isOnline} onClose={() => {}} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Geen internetverbinding</ModalHeader>
          <ModalBody>
            <Text>
              U bent momenteel offline. Maak verbinding met het internet om te kunnen transcriberen. 
            </Text>
<br></br>
            <Text> <i>U bent offline sinds  {offlineSince && (
                <> {offlineSince.toLocaleTimeString()}.</>
              )} </i></Text>
          </ModalBody>
          <ModalFooter>
            <ChakraButton colorScheme="blue" onClick={() => window.location.reload()}>
              Probeer opnieuw
            </ChakraButton>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {/* Mobile recording modal */}
      {isMobile && (
        <Modal
          isOpen={isRecording}
          onClose={stopRecording}
          size="full"
          isCentered
          closeOnOverlayClick={false} // Prevent closing by clicking outside
          closeOnEsc={false} // Prevent closing with ESC key
        >
          <ModalOverlay />
          <ModalContent
            height="100vh"
            bg={useColorModeValue("white", "gray.700")}
            display="flex"
            flexDirection="column"
            paddingY={4}
            paddingX={6}
          >
            <ModalHeader textAlign="center">
              Tijdens het transcriberen kunt u de app niet verlaten.
            </ModalHeader>
            <ModalBody flex="1" width="100%" overflow="hidden">
              <Flex
                direction="column"
                align="center"
                justify="flex-start"
                height="100%"
                width="100%"
              >
                {/* Icons */}
                <Flex align="center" justify="center" mb={4}>
                  <FaLock size={20} color="gray" />
                  <FaArrowRight
                    className="animate-bounce"
                    size={20}
                    color="gray"
                    style={{ marginLeft: "8px" }}
                  />
                </Flex>
                {/* Instruction Text */}
                <Text textAlign="center" mb={4}>
                  U kunt uw telefoon gewoon vergrendelen; de transcriptie zal
                  gewoon doorgaan.
                </Text>
                {/* Circular Waveform */}
                {isRecording && waveformVisible && (
                  <Box width={["100%", "80%", "60%"]} maxWidth="300px" mb={4}>
                    <CircularWaveform mediaStream={mediaStream} />
                  </Box>
                )}
                {/* Conversation Preview */}
                {(buffer || conversation) &&
                  isRecording &&
                  liveTranscriptionEnabled && (
                    <Box
                      ref={scrollRef}
                      mt={4}
                      flex="1"
                      width="100%"
                      overflowY="auto"
                      p={4}
                      border="1px solid"
                      borderColor={useColorModeValue("gray.200", "gray.600")}
                      borderRadius="md"
                      bg={useColorModeValue("gray.50", "gray.800")}
                      boxShadow="sm"
                    >
                      <Text whiteSpace="pre-wrap" fontSize="sm">
                        {conversation + " " + buffer}
                      </Text>
                    </Box>
                  )}
              </Flex>
            </ModalBody>
            <ModalFooter width="100%" paddingBottom={6}>
              <Button
                spinner={<PulseLoader size={10} color="white" />}
                leftIcon={<FaMicrophone />}
                onClick={handleClick}
                colorScheme="red"
                isLoading={waitingForSetup || isDisabled}
                isDisabled={isDisabled}
                width="100%"
                size="lg"
              >
                <Text fontSize="md">
                  {isRecording ? "STOP TRANSCRIBEREN" : "START TRANSCRIBEREN"}
                </Text>
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}

      {/* Desktop modal for navigation warning */}
      {!isMobile && (
        <Modal
          isOpen={false} // Assuming this is controlled elsewhere
          onClose={() => {}}
          isCentered
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Transcriberen bezig</ModalHeader>
            <ModalBody>
              <Text>
                U bent momenteel aan het transcriberen. Stop eerst de opname
                voordat u verder gaat om uw voortgang niet te verliezen.
              </Text>
            </ModalBody>
            <ModalFooter>
              <ChakraButton colorScheme="red" onClick={() => {}}>
                Sluiten
              </ChakraButton>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}

      {/* Main Recording Button */}
      <Flex position="relative" align="center">
        <HStack spacing="20px">
          <Box>
            <ButtonGroup size="sm" isAttached>
              <Button
                leftIcon={<FaMicrophone />}
                onClick={handleClick}
                colorScheme="red"
                isLoading={isDisabled}
                isDisabled={isDisabled}
                width="100%"
              >
                <Text fontSize="sm">
                  {isRecording ? "STOP TRANSCRIBEREN" : "START TRANSCRIBEREN"}
                </Text>
                
              </Button>
              {isRecording && (
    <Button
      variant="outline"
      colorScheme="red"
      onClick={handleTogglePause}
      isDisabled={isDisabled}
    >
      {isPaused ? "HERVAT" : "PAUZE"}
    </Button>
    
  )}

              {(!isMobile || isJuvV2) && (
                <Popover>
                  <PopoverTrigger>
                    <IconButton
                      colorScheme="red"
                      variant="outline"
                      aria-label="Live Transcription Settings"
                      icon={<SettingsIcon />}
                    />
                  </PopoverTrigger>
                  <Portal>
                    <PopoverContent borderColor="red">
                      <PopoverArrow />
                      <PopoverHeader>
                        Live Transcriptie Instellingen
                      </PopoverHeader>
                      <PopoverCloseButton />
                      {isJuvV2 && (
                        <PopoverBody>
                          Selecteer uw model:
                          <Select
                            onChange={(e) => setModel(e.target.value)}
                            value={model}
                            isDisabled={isRecording}
                            title={
                              isRecording
                                ? "Tijdens transriberen kunt u niet veranderen van model."
                                : ""
                            }
                          >
                            {availableV2Models.map((modelOption) => (
                              <option
                                key={modelOption.value}
                                value={modelOption.value}
                              >
                                {modelOption.label}
                              </option>
                            ))}
                          </Select>
                        </PopoverBody>
                      )}
                      {!isMobile && (
                        <PopoverBody>
                          Selecteer uw microfoon:
                          <Select
                            onChange={handleDeviceChange}
                            value={selectedDeviceId}
                            isDisabled={isRecording}
                            title={
                              isRecording
                                ? "Tijdens transcriberen kunt u niet veranderen van microfoon."
                                : ""
                            }
                          >
                            {devices.map((device) => (
                              <option
                                key={device.deviceId}
                                value={device.deviceId}
                              >
                                {device.label ||
                                  `Microphone ${device.deviceId}`}
                              </option>
                            ))}
                          </Select>
                        </PopoverBody>
                      )}
                    </PopoverContent>
                  </Portal>
                </Popover>
              )}
            </ButtonGroup>
          </Box>
          {/* For desktop, show WaveBox */}
          {!isMobile && isRecording && waveformVisible && (
            <Box
              position="absolute"
              left="100%"
              ml="10px"
              top="50%"
              transform="translateY(-50%)"
              opacity={isPaused ? 0.4 : 1} // Maak de golf half transparant bij pauze

              zIndex="1" // Adjust as needed
            >
              <WaveBox mediaStream={mediaStream} />
              {isPaused && (
      <Text ml={5} color="red.800" fontWeight="semibold">
        Opname gepauzeerd
      </Text>
    )}
  
            </Box>
          )}
        </HStack>
      </Flex>
    </>
  );
}

export default RecordingButton;
